<template>
  <div class="flex px-sm bg-status-success-lighter rounded-alt-xl">
    <div class="flex items-center text-sm bg-status-success-base">
      <FaIcon
        icon-class="fal fa-badge-percent"
        classes="text-black mx-xs !w-sm !h-sm md:!h-[20px] md:!w-[20px]"
      />
    </div>
    <div class="text-sm mt-[7px] mb-[6px] mx-sm">
      {{
        t(text, {
          discount: discount,
        })
      }}
    </div>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
defineProps({
  text: {
    type: String,
    required: true,
  },
  discount: {
    type: Number,
    require: true,
  },
});
const { t } = useTrans();
</script>
